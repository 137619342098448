h1,
h2,
h3,
h4,
h5 {
  color: $webeBlack;
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  -webkit-font-smoothing: initial;
}

h2,
h3 {
  font-size: 26px;
  letter-spacing: -1.3px;
  line-height: 1.2;
  margin: 0 0 31px;
  @media screen and (max-width: 767px) { margin: 0 0 20px; letter-spacing: -1px; }
  @media screen and (max-width: 500px) { font-size: 24px; }
}

h4 {
  font-size: 24px;
  letter-spacing: 0;
  @media screen and (max-width: 767px) { font-size: 22px; }
  @media screen and (max-width: 500px) { font-size: 20px; }
}


.full-width-content-darkgrey h2 {
  color: $white;
}

.purple-font {
  color: $webePurple;
  a:hover {
    text-decoration: underline;
  }
}

.orange-font {
  color: $webeOrange;
  a:hover {
    text-decoration: underline;
  }
}

.green-font {
  color: $webeGreen;
  a {
    color: $webeGreen !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

.white-font {
  color: $white !important;

  a {
    text-decoration: none;
    color: $white !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
a.white-font {
  color: $white !important;
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0 0 29px;
  font-size: 18px;
  line-height: 1.35;
  -webkit-font-smoothing: initial;

  @media screen and (max-width: 1023px) { font-size: 16px; }
  @media screen and (max-width: 767px) { font-size: 14px; margin: 0 0 20px;}
}
