#cms-main {
    overflow: hidden;
}
.js-tab-hidden {
    display: block !important;
    left: -9999px !important;
    position: absolute !important;
    top: -9999px !important;
}
.cms-landing .footer_section .about_wrapper {
    display: none;
}
.cms-hero {
    overflow: hidden;
    height: 100vh;
    position: relative;
    z-index: 3;
    min-height: 550px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    /* &:before {
        content:'';
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #000;
        opacity: 0.7;
    } */
    .container {
        position: relative;
        z-index: 2;
        margin: 0;
    }
    h1 {
        color: #fff;
        font-size: 49px;
        font-weight: 700;
        line-height: 1.07;
        letter-spacing: -2px;
        margin: 0 0 27px;
        text-transform: none;
    }
    img {
        position: absolute;
        z-index: -2;
        min-width: 100%;
        min-height: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        object-fit: cover;
    }
    video {
        position: absolute;
        z-index: -2;
        min-width: 100%;
        min-height: 100%;
        left: 0;
        top: 0;
    }
    a.request-services {
        margin: 36px auto 0;
        &:hover {
            color: $webeGreen !important; 
        }
    }
    .anchor-btn {
        position: absolute;
        left: 50%;
        bottom: 35px;
        width: 40px;
        height: 40px;
        text-align: center;
        opacity: 0.7;
        margin: 0  0 0 -20px;
        border-radius: 50%;
        background:rgba(37, 39, 40, 0.7);
        transition: background 0.3s;
        &:after {
            content:'';
            position: absolute;
            width: 12px;
            left: 50%;
            top: 50%;
            height: 12px;
            border: 3px solid #fff;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            margin:-7px 0 0 -6px;
        }
        &:hover {
            background:rgba(37, 39, 40, 1);
        }
    }
    @media screen and (max-width: 1023px) {
        min-height: 400px;
        h1 {
            font-size: 40px;
            letter-spacing: -1px;
        }
    }
    @media screen and (max-width: 767px) {
        h1 {
            font-size: 32px;
            letter-spacing: -1px;
        }
        .anchor-btn {
            bottom: 20px;
        }
    }
}
.cms-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-110%);
    transition: transform 0.3s;
    &.show {
        transform: translateY(0%);
    }
    h2 {
        margin: 0;
    }
    a.request-services {
        width: 234px;
        margin: 0 !important;
    }
    .container {
        display: flex;
        justify-content:space-between;
        align-items: center;
        padding-top: 23px;
        padding-bottom: 23px;
    }
    @media screen and (max-width: 767px) {
        .container {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        h2 {
            font-size: 16px;
            padding: 0 15px 0 0;
        }
        a.request-services {
            padding: 8px 0;
        }
    }
}
.line1 {
    position: relative;
    z-index: 2;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 30%;
        height: 75%;
        min-width: 400px;
        background:transparent url('../../assets/images/dashed-line1.svg') center 100% no-repeat;
        background-size: auto 100%;
    }
}
.cms-intro {
    padding: 80px 0 54px;
    .anim-block {
        margin: -40px 0 -54px;
        background:transparent url('../../assets/images/grass.svg') 50% 190% repeat-x;
        overflow: hidden;
        text-align: center;
        >* {
            display: inline-block;
            vertical-align: top;
            width: 320px;
            margin: 15px -10px -145px;
            position: relative;
            transform: translate3d(0,0,0);
            &:after {
                content: '';
                display: block;
                padding-bottom: 175%;
            }
            svg {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
        #wp-anim {
            margin: 0 -10px -145px;
            width: 380px;
        }
        @media screen and (max-width: 767px) {
            >* {
                width: 43%;
                margin: 0 -7px -18%;
            }
            #wp-anim {
                width: 45%;   
                margin: 0 -7px -18%;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0 40px;
        .anim-block {
            margin: -15px 0 -40px;
            background-position: 50%  100%;
            background-size: auto 48%;
        }
    }
}
.cms-tabs-container {
    overflow: hidden;
    .container {
        position: relative;
        max-width: 1250px;
    }
    .tabs {
        position: absolute;
        top: 82px;
        left: 50%;
        padding: 0;
        margin: 0;
        z-index: 2;
        transform: translateX(-50%);
        list-style: none;
        overflow: hidden;
        width: 494px;
        text-align: center;
        li {
            float: left;
            width: 50%;
        }
        a {
            color: #fff;
            text-transform: uppercase;
            background: #707071;
            transition: background 0.3s;
            display: block;
            line-height: 56px;
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            padding: 2px 0 0;
        }
        a.active {
            background: #262728;
        }
        @media screen and (max-width: 1023px) {
            top: 50px;
            a {
                line-height: 50px;
            }
        }
        @media screen and (max-width: 767px) {
            top: 30px;
            width: calc(100% - 40px);
            a {
                line-height: 40px;
            }
        }
    }
    .row {
        display: flex;
        flex-direction: row-reverse;
        justify-content:space-between;
        @media screen and (max-width: 767px) {
            display: block;
        }
    }
    .right-col {
        width: 47%;
        flex: 0 0 47%;
        position: relative;
        z-index: 1;
        >div {
            top:-0.5%;
            right: 6%;
            left: -3%;
            position: absolute;
            svg {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
            &:after {
                display: block;
                content:'';
                padding-top: 121%;
            }
            @media screen and (max-width: 1023px) {
                right: -15px;
                left: -20%;
            }
            @media screen and (max-width: 1023px) {
               
            }
        }
        #tab-wp-anim {
            right: 1%;
            left: 2%;
            top: 0;
            &:after {
                padding-top: 134%;   
            }
            @media screen and (max-width: 1023px) {
                right: -35px;
                left: -12%;
            }
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            >div{
                position: relative;
                top: 0;
                display: block;
                left: -15px;
                width: calc(100% + 30px);
            }
            #tab-wp-anim {
                left: 0;
                margin-bottom: -14%;
            }
        }
    }
    a.request-services.solutions {
        background: $webeGreen;
        border: solid 2px $webeGreen;
        margin-top: 46px;
        max-width: 325px;

        &:hover {
            background: none;
            color: $webeGreen;
        }
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
      }
    .left-col {
        width: 40%;
        flex: 0 0 40%;
        padding: 226px 0 40px;
        min-height: 720px;
        @media screen and (max-width: 1023px) {
            width: 43%;
            flex: 0 0 43%;
            padding: 170px 0 30px;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            min-height: 0;
            padding: 25px 0 35px;
        }
    }
}
.cms-tool {
    padding: 95px 0 157px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    @media screen and (max-width: 1023px) {
        padding: 80px 0 120px;
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0 30px;
    }
    .txt-block {
        margin: 0 0 62px;
        @media screen and (max-width: 767px) {
            margin: 0 0 40px;
        }
    }
    .line2 {
        position: absolute;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
        width: 25%;
        height: 50%;
        background:transparent url('../../assets/images/dashed-line2.svg') 0 0 no-repeat;
        background-size: 100% auto;
        @media screen and (max-width: 767px) {
            width: 50%;
        }
    }
    .line3 {
        position: absolute;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        width: 30%;
        height: 80%;
        background:transparent url('../../assets/images/dashed-line3.svg') 100% 100% no-repeat;
        background-size: 100% auto;
        @media screen and (max-width: 767px) {
            width: 50%;
        }
    }
}
.cms-grid {
    max-width: 780px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    color: #252728;
    margin: 0 auto 87px;
    @media screen and (max-width: 1023px) {
        margin: 0 0 70px;
    }
    @media screen and (max-width: 767px) {
        margin: 0 0 50px;
    }
    > {
        div {
            width: 25%;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: -3px;
                left: 0;
                right: 0;
                background-size: 12px 2px;
                background-repeat: repeat-x;
                background-position: 0 0;
                height: 3px;
                background-image: -webkit-gradient(linear,left top,right top,color-stop(15%,#bac1c2),color-stop(16%,#d6dede));
                background-image: -webkit-linear-gradient(left,#bac1c2 15%,#d6dede 16%);
                background-image: -o-linear-gradient(left,#bac1c2 15%,#d6dede 16%);
                background-image: linear-gradient(to right,#bac1c2 15%,#d6dede 16%);
            }
            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                top: -4px;
                bottom: 0;
                right: -3px;
                background-image: -webkit-gradient(linear,left top,left bottom,color-stop(15%,#bac1c2),color-stop(16%,#d6dede));
                background-image: -webkit-linear-gradient(top,#bac1c2 15%,#d6dede 16%);
                background-image: -o-linear-gradient(top,#bac1c2 15%,#d6dede 16%);
                background-image: linear-gradient(to bottom,#bac1c2 15%,#d6dede 16%);
                background-size: 2px 12px;
                background-repeat: repeat-y;
                background-position: 0 0;
                width: 3px;
            }
            @media screen and (max-width: 767px) {
                width: 50%;
            }
        }
    }
    .box {
        height: 100%;
        position: relative;
        text-align: center;
        padding-top: 100%;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: #252728;
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }
    .name {
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        width: 100%;
        transform: translateY(-50%);
        .title {
            min-height: 36px;
        }
        @media screen and (max-width: 767px) {
            font-size: 14px;
            .title {
                min-height: 32px;
            }
        }
    }
    img {
        display: block;
        margin: 0 auto 15px;
        height: 42px;
        width: auto;
        transition: transform 0.3s ease;
    }
}
.cms-info {
    position: relative;
    max-width: 1050px;
    padding: 0 30px;
    margin: 0 auto;
    text-transform: uppercase;
    #tools {
        width: 111%;
        margin: 0 -5% 3px;
        @media screen and (max-width: 767px) {
            width: calc(100% + 60px);
            margin: 0 -26px;
        }
    }
    .grid {
        display: flex;
        justify-content:space-between;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .holder {
        position: relative;
        z-index: 2;
        width: 25%;
        &:last-child {
            .circle:after {
                display: none;
            }
            .circle img {
                width: 131%;
                margin:-27% 0 0 -10.5%;
            }
        }
        @media screen and (max-width: 767px) {
            margin: 0 0 50px;
        }
    }
    .circle {
        position: relative;
        z-index: 2;
        width: 245px;
        height: 245px;
        border-radius: 50%;
        margin: 0 0 81px;
        border: 5px solid #717171;
        img{
            width: 100%;
            height: auto;
            max-width: none;
        }
        &:after {
            content:'';
            position: absolute;
            top: 50%;
            width: 100%;
            left: 95%;
            height: 5px;
            z-index: -1;
            background:#717171;
            margin-top: -2px;
        } 
        @media screen and (max-width: 1023px) {
            width: 190px;
            height: 190px;
            margin: 0 0 50px;
            &:after {
                width: 120%;
            }
        }
        @media screen and (max-width: 767px) {
            width: 220px;
            height: 220px;
            margin: 0 auto 25px;
            &:after {
                display: none;
            }
        }
    }
    p {
        margin: 0;
        color: #252728;
        font-size: 25px;
        line-height: 1;
        @media screen and (max-width: 1023px) {
            font-size: 20px;
        }
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
}
.cms-block {
    padding: 84px 0 0;
    a.request-services.solutions {
        margin-top: 41px;
    }
    .container {
        position: relative;
        z-index: 3;
    }
    @media screen and (max-width: 1023px) {
        padding: 80px 0 0;
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0 0;
        a.request-services.solutions {
            margin-top: 30px;
        }
    }
}
.laptop {
    text-align: center;
    margin:calc(-3vw - 10px) 0 0;
    padding: 0 0 0 53px;
    @media screen and (max-width: 1023px) {
        padding: 0 0 0 3%;
    }
    @media screen and (max-width: 767px) {
        margin:0;
    }
    video {
        display: block;
        margin: 0 auto;
        background: #fff;
        max-width: 100%;
        height: auto;
    }
    .frame {
        display: inline-block;
        vertical-align: top;
        position: relative;
        overflow: hidden;
        &:before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: -5px;
            border: 5px solid #fff;
        }
    }
}
.cms-slider {
    padding: 83px 0 95px; 
    position: relative;
    z-index: 2;
    background:$lightGrey  url('../../assets/images/dashed-line5.svg') center 35% no-repeat;
    background-size: auto 160%;
    > h2 {
        margin: 0 0 46px;
    }
    @media screen and (max-width: 1023px) {
        padding: 80px 0;
        background-position: 30px 35%;
        > h2 {
           margin: 0 0 35px;
        }
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0 40px;
        > h2 {
           margin: 0 0 30px;
        }
    }
    .card {
        padding: 0 8px;
        text-align: left;
        .holder {
            color: #4f585d;
            height: 100%;
            min-height: 288px;
            position: relative;
            background: #fff;
            display: block;
            text-decoration: none;
            padding: 33px 36px 60px; 
        }
        h2 {
            color: #4f585d;
            margin: 0 0 2px;
        }
        p {
            font-size: 16px;
            margin: 0;
        }
        @media screen and (max-width: $widescreen) {
            .holder {
                padding: 30px 25px 55px;
            }
        }
    }
    .slick-track {
        display: flex;
        .slick-slide {
            min-height: 100%;
            height: auto;
        }
    }
    .slick-slider {
        padding: 0 40px;
    }
    .slick-list {
        max-width: 1140px;
        margin: 0 auto;
    }
    .category {
        color: $webeGreen;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0 0 7px;
        display: block;
    }
    .anim-btn {
        position: absolute;
        right: 17px;
        bottom: 19px;
        color: $webeBlack;
        border-color: $webeBlack;
        .arrow:before {
            border-color: $webeBlack;
        }
        .arrow:after {
            background: $webeBlack;
        }
        @media screen and (max-width: $widescreen) {
            right: 20px;
            bottom: 20px;
        }
    }
}
.cms-cta {
    position: relative;
    z-index: 3;
    text-align: center;
    padding: 83px 0 105px;
    background: $webeGreen;
    color: #fff;
    &:before {
        content:'';
        position: absolute;
        left: -126px;
        bottom: -28px;
        width: 300px;
        height: 350px;
        z-index: -1;
        background: url('../../assets/images/wp-character.png') 0 100% no-repeat;
        background-size: 100% auto;
    }
    &:after {
        content:'';
        position: absolute;
        right: -63px;
        bottom: -10px;
        width: 295px;
        height: 350px;
        z-index: -1;
        background: url('../../assets/images/drupal-character.png') 100% 100% no-repeat;
        background-size: 100% auto;
    }
    a.request-services.solutions {
        margin-top: 44px;
    }
    @media screen and (max-width: 1023px) {
        padding: 80px 0 100px;
        &:before {
            width: 200px;
            left: -84px;
            bottom: -20px;
        }
        &:after {
            width: 200px;
            right: -50px;
            bottom: -8px;
        }
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0 80px;
        &:before {
            width: 120px;
            left: -49px;
            height: 200px;
            bottom: -13px;
        }
        &:after {
            width: 120px;
            height: 200px;
            right: -27px;
            bottom: -5px;
        }
        a.request-services.solutions {
            margin-top: 30px;
        }   
    }
    h2 {
        color: #fff;
        margin: 0 0 38px;
        @media screen and (max-width: 767px) {
            margin: 0 0 25px;
        }
    }
    .request-services {
        margin: 0 auto;
        background: #fff !important;
        border-color: #fff !important;
        color:  $webeGreen;
        &:hover {
            background: transparent !important;
            color: #fff !important;
        }
    }
}
.cms-cta-h {
    background:$darkGrey;
    color: #fff;
    padding: 76px 0 78px;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    p {
        margin: 0;
    }
    a.request-services.work-overview {
        margin: 0 7px 0 0;
        
        width: 270px;
        &:hover {
            color: $webeGreen;
        }
    }
    .txt-block {
        width: 60%;
        padding: 0 0 0 10px;
    }
    @media screen and (max-width: 1023px) {
        padding: 65px 0;
        .txt-block {
            padding: 0;
        }
        a.request-services.work-overview {
            margin: 0;
        }
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0;
        .container {
            display: block;
            text-align: center;
        }
        .txt-block {
            width: 100%;
        }
        a.request-services.work-overview {
            margin: 30px auto 0;
        }
    }
}