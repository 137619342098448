@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clear {
  clear: both;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

// devices viewport width starts at:
$widescreen: 1250px;
$max_width: 1200px;

.v-align {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.v-align-only {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  @media screen and (max-width: 980px) {
    position: relative;
    top: auto;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    width: auto;
    height: 100%;
  }

}
