.fancybox-content {
    background: #d6dede;
    padding: 45px;
    @media screen and (max-width: 1023px) {
        width: 90%;
        &.card-popup {
            width: auto;
        }
    }
    @media screen and (max-width: 767px) {
        padding: 20px;
        width: 94%;
    }
}
.popup {
    text-align: center;
    width: 800px;
    h2 {
        font:400 24px/1.3 'Roboto Slab', serif;
        margin: 0 0 15px;
        color: #707071;
    }
    video {
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 1023px) {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        h2 {
            font-size: 22px;
        }
    }
}
.popup-holder {
    height: 0;
    position: relative;
    overflow: hidden;
}
.card {
    width: 530px;
    position: relative;
    text-align: center;
    padding: 43px 0;
    min-height: 390px;
    @media screen and (max-width: 1023px) {
        width: 450px;
        min-height: 340px;
        padding: 35px 0;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        min-height: 0;
        padding: 20px 0 40px;
    }
    .icon {
        display: block;
        margin: 0 0 8px;
        img {
            height: 56px;
            width: auto;
            display: block;
            margin: 0 auto;
        }
        @media screen and (max-width: 1023px) {
            img {
                height: 45px;
            }
        }
        @media screen and (max-width: 767px) {
            img {
                height: 40px;
            }
        }
    }
    .title {
        color: #706f71;
        font: 400 25px/1.3 'Roboto Slab', serif;
        margin: 0 0 7px;
        @media screen and (max-width: 1023px) {
            font-size: 22px;
        }
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    .text {
        color: #706f71;
        font-size: 20px;
        margin: 0 auto 35px;
        max-width: 355px;
        @media screen and (max-width: 1023px) {
            font-size: 16px;
            max-width: 280px;
            margin: 0 auto 25px;
        }
        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }
    .btn {
        transition: background 0.3s;
        min-height: 42px;
        font-size: 0;
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            opacity: 1;
            font-size: 12px;
            content:'ADD TO CMS NEEDS';
            transition: opacity 0.3s 0.3s;
        }
        &:before {
            transition: opacity 0.3s;
            position: absolute;
            left: 0;
            right: 0;
            font-size: 12px;
            opacity: 0;
            content:'remove from CMS needs';
        }
    }
    .added {
        &:after {
            opacity: 0;
            transition-delay:0s;
        }
        &:before {
            transition-delay:0.3s;  
            opacity: 1;
        }
    }
    .btn-row {
        position: absolute;
        bottom: -23px;
        left: -25px;
        right: -25px;
        overflow: hidden;
        a {
            text-transform: uppercase;
            white-space: nowrap;
            line-height: 26px;
            border: 1px solid #000;
            border-radius: 2px;
            font-weight: bold;
            font-size: 12px;
            text-decoration: none;
            color: #000;
            transition: all 0.3s ease;
            background: transparent;
            padding: 0 24px 0 0;
            position: relative;
            height: 26px;
            .arrow {
                width: 22px;
                height: 16px;
                position: absolute;
                top: 4px;
                display: block;
                max-width: none;
                right: 5px;
                overflow: visible;
                transition: all 0.3s ease;
                &:before {
                    content: '';
                    position: absolute;
                    transition: all 0.3s ease;
                    right: 1px;
                    top: 50%;
                    width: 9px;
                    height: 9px;
                    border: 2px solid #000;
                    border-width: 0 2px 2px 0;
                    transform: translateY(-51%) rotate(-45deg);
                }
                &:after {
                    transition: all 0.3s ease;
                    content: '';
                    position: absolute;
                    right: 1px;
                    top: 50%;
                    width: 14px;
                    height: 2px;
                    background: #000;
                    margin-top: -1px;
                }
            }
            &:hover {
                background: #fff;
                border: 1px solid transparent;
                .name {
                    max-width: 250px;
                    padding: 0 5px 0 10px;  
                }
            }
        }
        .name {
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            color: #000;
            padding: 0;
            transition: all 0.3s ease;
            max-width: 0;
        }
        @media screen and (max-width: 767px) {
            left: -10px;
            right: -10px;
            bottom: -10px;
            a {
                font-size: 12px;
            }
        }
    }
    .js-prev-card {
        float: left;
        padding: 0  0 0 24px !important;
        .arrow {
            transform: rotate(180deg);
            right: auto;
            left: 5px;
        }
        &:hover .name {
            padding: 0 10px 0 5px !important;
        }
    }
    .js-next-card {
        float: right;
    }
}