@import "global/reset";
@import "global/fancybox";

@import "base/colors";
@import "base/fonts";
@import "base/common";

@import "base/slick";
@import "base/slick-images";
@import "base/slick-theme";
@import "base/footer-about";
@import "base/cms-landing";
@import "base/popup";


* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "proxima-nova", arial, sans-serif;
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  color: $darkGrey;
}
a {
  cursor: pointer;
  color: $webeOrange;
}

img {
  display: block;
  border: 0 none;
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.padding,
.container.padding {
  padding-top: 71px;
  padding-bottom: 85px;
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 580px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

#wrapper {
  background: $white;
}


.light-grey-bg {
  background: $lightGrey;
}


a.request-services {
  border-radius: 2px;
  color: $white;
  text-decoration: none;
  padding: 11px 0 10px;
  text-align: center;
  margin: 10px 0 0;
  text-transform: uppercase !important;
  font-size: 12px;
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  transition: all 0.3s;
  font-family: proxima-nova !important;


  &.case-studies {
    background: none;
    color: $webeBlack;
    border: solid 2px $webeBlack;
    max-width: 234px;
    margin: 0 auto;

    &:hover {
      background: $webeBlack;
      color: $white;
    }
  }

  &.work {
    background: $webeOrange;
    max-width: 234px;
    border: solid 2px $webeOrange;
    margin-top: 30px;

    &:visited {
      color: $white;
    }

    &:hover {
      background: none;
      color: $webeOrange;
    }
  }

  &.work-overview {
    background: none;
    max-width: 234px;
    border: solid 2px $white;
    margin-top: 36px;

    &:hover {
      background: $white;
      color: $webeOrange;
    }
  }

  &.solutions {
    background: $webeGreen;
    border: solid 2px $webeGreen;
    margin-top: 36px;
    max-width: 234px;

    &:hover {
      background: none;
      color: $webeGreen;
    }
  }

  &.solutions-overview {
    background: none;
    max-width: 270px;
    border: solid 2px $white;
    margin-top: 36px;

    &:hover {
      background: $white;
      color: $webeGreen;
    }
  }


  &.pink {
    background: $pink;
    max-width: 270px;
    margin: 0 auto;
    border: solid 2px $pink;

    &:hover {
      background: none;
      color: $pink;
    }
  }
}

.anim-btn {
  display: inline-block;
  vertical-align: top;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  line-height: 24px;
  border:1px solid $white;
  border-radius: 2px;
  position: relative;
  padding: 0 24px 0 0;
  overflow: hidden;
  transition: all 0.3s ease;
  background: transparent;
  span {
    display: inline-block;
    vertical-align: top;
    max-width: 0;
    overflow: hidden;
    color: $webeBlack;
    transition: all 0.3s ease;
  }
  .arrow {
    width: 16px;
    height: 12px;
    position: absolute;
    top: 6px;
    display: block;
    max-width: none;
    right: 5px;
    overflow: visible;
    transition: all 0.3s ease;
    &:before {
      content:'';
      position: absolute;
      transition: all 0.3s ease;
      right: 0;
      top: 50%;
      width: 9px;
      height: 9px;
      border: 2px solid $white;
      border-width: 0 2px 2px 0;
      transform:translateY(-50%) rotate(-45deg);
    }
    &:after {
      transition: all 0.3s ease;
      content:'';
      position: absolute;
      right: 1px;
      top: 50%;
      width: 14px;
      height: 2px;
      background: $white;
      margin-top: -1px;
    }
  }
  .ua-mobile & {
    background: $white;
    span {
      max-width: 200px;
      padding: 0 5px 0 10px;
    }
    .arrow:before {
      border-color: $webeBlack;
    }
    .arrow:after {
      background: $webeBlack;
    }
  }
  @media screen and (max-width: 1023px) {
    background: $white;
    span {
      max-width: 200px;
      padding: 0 5px 0 10px;
    }
    .arrow:before {
      border-color: $webeBlack;
    }
    .arrow:after {
      background: $webeBlack;
    }
  }
}
a:hover .anim-btn {
  background: $white;
  span {
    max-width: 200px;
    padding: 0 5px 0 10px;
  }
  .arrow:before {
    border-color: $webeBlack;
  }
  .arrow:after {
    background: $webeBlack;
  }
}

.centered {
  text-align: center;
  .request-services {
    margin: 0 auto;
  }
}
.container {
  max-width: 1030px;
  padding: 0 40px;
  margin: 0 auto;
  font-size: 18px;
  .small-container {
    padding-left: 0;
    padding-right: 0;
  }
  h3 {
    //font:30px/1.2 'Roboto Slab', serif;
    margin: 0 0 18px;
    letter-spacing: -1.3px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 16px;
    h3 {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 780px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    h3 {
      margin: 0 0 12px;
    }
  }
}
.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.pulse-large {
    -webkit-animation-name: pulse-large;
    -o-animation-name: pulse-large;
    animation-name: pulse-large;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
}
.animated {
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  13% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  25% {
    -webkit-transform: scale3d(1.10, 1.10, 1.10);
    -ms-transform: scale3d(1.10, 1.10, 1.10);
    transform: scale3d(1.10, 1.10, 1.10);
  }

  37% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


@keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  13% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }

  25% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25);
    -ms-transform: scale3d(1.25, 1.25, 1.25);
    transform: scale3d(1.25, 1.25, 1.25);
  }

  37% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }

  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}