@charset "UTF-8";
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

h1, h2, h3, h4, h5, h6, p,
fieldset, form, label,
table, tbody, tr, th, td,
figure, footer, header,
menu, nav, section,
audio, video, button {
  margin: 0;
  border: 0; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: #707071;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: #fff;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -44px;
  z-index: -1; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate 1s linear infinite;
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-caption {
    padding: 12px; }
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

h1,
h2,
h3,
h4,
h5 {
  color: #2b3337;
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  -webkit-font-smoothing: initial; }

h2,
h3 {
  font-size: 26px;
  letter-spacing: -1.3px;
  line-height: 1.2;
  margin: 0 0 31px; }
  @media screen and (max-width: 767px) {
    h2,
    h3 {
      margin: 0 0 20px;
      letter-spacing: -1px; } }
  @media screen and (max-width: 500px) {
    h2,
    h3 {
      font-size: 24px; } }

h4 {
  font-size: 24px;
  letter-spacing: 0; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 22px; } }
  @media screen and (max-width: 500px) {
    h4 {
      font-size: 20px; } }

.full-width-content-darkgrey h2 {
  color: #ffffff; }

.purple-font {
  color: #a072a5; }
  .purple-font a:hover {
    text-decoration: underline; }

.orange-font {
  color: #ef9123; }
  .orange-font a:hover {
    text-decoration: underline; }

.green-font {
  color: #a3cf5f; }
  .green-font a {
    color: #a3cf5f !important; }
    .green-font a:hover {
      text-decoration: underline; }

.white-font {
  color: #ffffff !important; }
  .white-font a {
    text-decoration: none;
    color: #ffffff !important; }
    .white-font a:hover {
      text-decoration: underline; }

a.white-font {
  color: #ffffff !important; }
  a.white-font:hover {
    text-decoration: underline; }

p {
  margin: 0 0 29px;
  font-size: 18px;
  line-height: 1.35;
  -webkit-font-smoothing: initial; }
  @media screen and (max-width: 1023px) {
    p {
      font-size: 16px; } }
  @media screen and (max-width: 767px) {
    p {
      font-size: 14px;
      margin: 0 0 20px; } }

.clear {
  clear: both; }

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden; }

.v-align {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.v-align-only {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media screen and (max-width: 980px) {
    .v-align-only {
      position: relative;
      top: auto;
      -ms-transform: none;
      -webkit-transform: none;
      transform: none;
      width: auto;
      height: 100%; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #ffffff "/img/ajax-loader.gif" center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: slick-font-url("slick.eot");
  src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 42px;
  height: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background-color: transparent;
  top: 50%;
  margin-top: -10px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 3; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none; }

.slick-prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAbFBMVEUAAAAAAADQ0NAODg68vLweHh4EBASlpaUoKCgAAAC1tbXU1NTIyMjFxcWOjo5zc3NWVlZKSko9PT0zMzOvr6+bm5uCgoJnZ2cWFhYAAAAAAADMzMxjY2MAAAAAAAAAAAAAAAAAAAAAAADV1dXAdPAZAAAAI3RSTlO/AP3D9sfA7cm48/76+ebe1dLOzPHq4trEYFv82JmXeRQYF4JmdtwAAADmSURBVHgB7dhJcsIwEEbhHw+yZGYwBDtzdP87Rr3N9qlSpqq/A7wFuCVVC0nv88+mEJUWq3CfVuGWKpX0pRpm1fChGpKelHMujlHYZZe7VtCpyTkPYo652L6ICAeLNGcR7WCR/VVEP1qk60Vc9xYZWhHnV4scgoi3rUWOQu7WaE4iws0iu4uIx2SRLoqInUWmBx2/4tby8ct3/eHjN/ZCJos0IAKOAvC7gP8IfC/o2w0C8BzxmebnCz/r+LnL7wB+H9W/G304+buOvzH/7b3rnHNpRZu2eT0byKX+ZhZvicHG+ntT/AKXpB15Ebi8agAAAABJRU5ErkJggg==) !important;
  background-size: 100%;
  border-radius: 0 8px 8px 0;
  left: 0; }
  .slick-prev:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAclBMVEUAAAAAAAAVFRX4+PgkJCQwMDAAAAAAAADx8fHs7Ozj4+PZ2dmrq6t6enpZWVk9PT0JCQnS0tL8/Pzd3d3FxcW5ubmbm5tmZmZJSUkAAAAAAACIiIjHx8fCwsKNjY1ra2tMTEwAAAAAAAAAAACIiIj///9953URAAAAJXRSTlO/AMP9x8m4Xfv59/Pm2tLMwfH+9O3q4tTOmBXd7uze1s55AgHcH20CeAAAAONJREFUeAHtmAlOgDAQRSuFln1BQQFx7/2vKHOGN4mYzDvAC6H9M813iLDF/eHCUUL8Fgvm80vD4qKKJexOg6hi2VQswRmG8U8pmoJLXspUZ1Ty7FNKJ5QcSeiYpBWH75EjexJJNSBJ3ojkMUeSoRLJKzuf3oukHZGkm0Xy5hCrOOZ35BgXkZQf7IQnkdQFi18tkimj8btYRh6/tFr8lOPnJpF4keh/C/8v/IwIP/y+8LvLc6SfaT5f+Kzjc5fvALiPwG60cB6K4Tz/4o0J3ruGYRjhRk1bvE8DGfWbWd4Sw8b6F07YH1gCv+gyAAAAAElFTkSuQmCC) !important;
    background-size: 100%; }

.slick-next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAb1BMVEUAAAAAAADPz88ODg4EBASkpKQZGRkAAAC0tLQqKirT09PHx8fExMS9vb2Ojo5lZWVKSko9PT0zMzMiIiKurq4AAACBgYFxcXFVVVUAAAAAAAC5ubnLy8ubm5uZmZl1dXVZWVkAAAAAAAAAAADU1NTLGDBqAAAAJHRSTlO/AP3DwO3FXvPK/vr59+ba0s7MyPG44t3UmBX0/Orp3ta7erJrDvygAAAA7klEQVR42u3YVw6DMBAE0KEaSOglhfT4/mcMyw+/SOOIRNp3gBFmvba18CZnc+rBkBTzhOBSXuB5Bg6cezhg4MIJLvRQSqkV0iQFLcjtrgYrstb6R5DKzE4OIFW+xBQhOM1eYqIAnHaUmCRm6xRJzL4BJywk5lKBdJeYrATpMZdqAKneScwtZBsql5iOLVXcSUzeshW/SgzdnOFgtTm/3JztvKiODEmYb+H/C18j9/tl2btb9tHS06nr84U/6/hzl78D+Pto+7uxHbX9VrzrqDcm/95VSv2p/ocmbQYbTCCJaSgxmd1kSjxPrN9gfACctB59h6DZJwAAAABJRU5ErkJggg==) !important;
  background-size: 100%;
  border-radius: 8px 0 0 8px;
  right: 0; }
  .slick-next:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAe1BMVEUAAAAAAAAVFRUkJCTFxcUAAAD6+vr39/cAAADx8fHs7Ozj4+PZ2dmrq6uKiop6enpZWVk9PT0vLy8JCQn+/v7d3d1mZmZJSUkAAAAAAADT09PQ0NC7u7u4uLienp6ZmZlra2tMTEw1NTUAAAAAAAAAAAAAAAAAAAD////KoiNuAAAAKHRSTlO/AMPH7V7+/Ln7+ffz5t7a0szJwf701M6YFfHw6uni4dbOygIBsnt5sh7/KwAAAO5JREFUeAHt2GlOhTAUxfEWKPMMKuAsDt3/CuXGDZj8a+yHexbwy3u0p22usVd2t5UGxdp3BwlRzi/DY50JkL0MoTgTIlsQ5Vd/SKPRaN7amSNJ46s7rGTe+/yWKsPqr/SUGQthOspMtTBZApn0Rpg2peuUCVNPkFk6YYrRwPTC5ANlXnJxninzWgnzuEBmboS5pyueHMI0tJzLgzC8nE/+j8up5UwLYY4Ifgv4LmCNwH4Bexf0iHeany/8rOPnLr8D1uH/7yN+N2r9+LuOvzH5e1ej0cSaMqJJmzPxTCCtC6KcnxwJNSX+mVh/IOQbKd0hQUg0ZOcAAAAASUVORK5CYII=) !important;
    background-size: 100%; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-slider-work {
  margin-bottom: 0px; }

.fs-instly .slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 1; }

/*** main footer ***/
.footer_head_wrapper {
  width: 100%;
  background: #252728;
  line-height: 42px;
  color: #707071;
  height: 42px; }
  .footer_head_wrapper.fixed {
    left: 0px;
    bottom: 0px;
    z-index: 99; }

.footer_head {
  max-width: 1200px;
  position: relative;
  margin: auto; }
  .footer_head .copyright {
    float: left;
    font-size: 12px; }
  .footer_head a.connect {
    float: right;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none !important; }
    .footer_head a.connect div {
      display: inline-block;
      background-color: #ef9123;
      border-radius: 2px;
      width: 14px;
      height: 14px;
      padding: 2px;
      margin: -2px 0 0 16px;
      vertical-align: middle; }
    .footer_head a.connect img {
      transition: all 140ms ease-in-out;
      width: 100%; }
  .footer_head .footer_content {
    height: 300px;
    display: none; }

.footer_section {
  background: #d6dede; }
  .footer_section i {
    background: url("/img/sprite.png") no-repeat;
    background-size: 150px;
    width: 30px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 -4px; }
    .footer_section i.pdf {
      background-position: 0 -30px; }
    .footer_section i.phone {
      background-position: -30px -30px;
      margin: 6px 5px 0 -4px; }
    .footer_section i.mail {
      background-position: -60px -30px; }

.about_wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media screen and (max-width: 580px) {
    .about_wrapper {
      padding: 20px; } }
  .about_wrapper h4 {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -1.3px;
    margin: 0 0 14px; }
  .about_wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #2b3337;
    font-size: 16px; }
    .about_wrapper ul li {
      padding: 0 0 4px; }
    .about_wrapper ul a {
      color: #2b3337;
      text-decoration: none; }
  .about_wrapper p {
    font-size: 16px;
    line-height: 1.2; }
  .about_wrapper .about-contact-us {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0;
    box-sizing: border-box; }
    .about_wrapper .about-contact-us .request-services {
      margin: 0;
      max-width: 200px; }
    .about_wrapper .about-contact-us img.about-icon-pdf {
      display: inline-block;
      width: 18px;
      vertical-align: top;
      margin: -3px 8px 0 0; }
    .about_wrapper .about-contact-us img.about-icon-phone {
      display: inline-block;
      width: 15px;
      margin-right: 8px;
      vertical-align: top;
      margin: -4px 8px 0 0; }
    .about_wrapper .about-contact-us img.about-icon-email {
      display: inline-block;
      width: 20px;
      vertical-align: top;
      margin: 3px 8px 0 0; }
    @media screen and (max-width: 1200px) {
      .about_wrapper .about-contact-us {
        flex-basis: auto; } }
    @media screen and (max-width: 767px) {
      .about_wrapper .about-contact-us {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: solid 1px #707071; } }
  .about_wrapper .about-social {
    flex-basis: 0;
    flex-grow: 1;
    padding-left: 50px; }
    .about_wrapper .about-social .nav-social-box {
      text-align: left;
      white-space: nowrap; }
    @media screen and (max-width: 1200px) {
      .about_wrapper .about-social {
        padding-left: 25px; } }
    @media screen and (max-width: 1100px) {
      .about_wrapper .about-social {
        flex: 1 0 auto; } }
    @media screen and (max-width: 1023px) {
      .about_wrapper .about-social {
        flex: 1 0 100%;
        padding-top: 30px;
        padding-left: 0; } }
    @media screen and (max-width: 767px) {
      .about_wrapper .about-social {
        padding-top: 0; } }
  .about_wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .about_wrapper a:not(.request-services):hover {
    color: #ef9123; }
    .home .about_wrapper a:not(.request-services):hover {
      color: #e74769; }
    .what-we-do .about_wrapper a:not(.request-services):hover {
      color: #a3cf5f; }
    .about .about_wrapper a:not(.request-services):hover {
      color: #a072a5; }
    .insights .about_wrapper a:not(.request-services):hover {
      color: #62b4e5; }
  .about_wrapper .about-link {
    text-transform: uppercase;
    color: #2b3337;
    text-decoration: none;
    font-size: 12px; }
  .about_wrapper a {
    color: #2b3337;
    text-decoration: none; }
  .about_wrapper .about-link-break {
    display: none; }
  .about_wrapper .phone-link {
    position: relative;
    padding: 0 0 0 32px;
    font-size: 14px;
    margin: 15px 0 -6px; }
    .about_wrapper .phone-link .phone {
      position: absolute;
      left: -3px;
      top: 50%;
      margin: 0;
      transform: translateY(-45%); }

.footer-holder {
  background: #2b3337;
  color: #d6dede;
  padding: 20px 0; }
  .footer-holder .container {
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    max-width: 1278px; }
  .footer-holder .left {
    float: left;
    color: #707071;
    width: 50%; }
    .footer-holder .left p {
      padding: 0;
      font-size: 12px;
      line-height: 32px; }
  .footer-holder p {
    margin: 0; }
  .footer-holder .right {
    float: right;
    padding: 2px 0 0; }
    .footer-holder .right img {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px 3px; }
    .footer-holder .right p {
      padding: 5px 0 0;
      font-size: 16px; }
  .footer-holder .logo {
    float: left;
    width: 33px;
    height: 32px;
    margin: 0 30px 0 0;
    overflow: hidden;
    opacity: 0.25; }
    .footer-holder .logo img {
      width: auto;
      max-width: none;
      height: 32px; }
  @media screen and (max-width: 767px) {
    .footer-holder .logo {
      float: none;
      display: inline-block;
      vertical-align: top;
      margin: 0; }
    .footer-holder .right,
    .footer-holder .left {
      float: none;
      width: 100%;
      text-align: center; } }

#mc_embed_signup {
  overflow: hidden;
  padding-bottom: 4px; }
  #mc_embed_signup .mc-field-group {
    overflow: hidden;
    position: relative;
    padding: 0 40px 0 0;
    width: 100%;
    max-width: 230px; }
  #mc_embed_signup [type="email"] {
    display: block;
    width: 100%;
    background: #ffffff;
    border: none;
    padding: 0 18px;
    height: 40px;
    line-height: 40px;
    color: #707071; }
  #mc_embed_signup button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    border: none;
    border-radius: 0 !important;
    padding: 0;
    margin: 0;
    height: 40px;
    color: #ffffff;
    background: #ef9123;
    cursor: pointer;
    z-index: 2; }
    #mc_embed_signup button svg {
      display: inline-block;
      vertical-align: middle;
      fill: #ffffff;
      width: 16px;
      height: 14px; }
    #mc_embed_signup button:hover {
      background: #2b3337 !important; }

#mc_embed_signup div.mce_inline_error {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  background-color: #e04444 !important; }

.work .about_wrapper .about-contact-us .request-services {
  background: #ef9123;
  border: solid 3px #ef9123; }
  .work .about_wrapper .about-contact-us .request-services:hover {
    background: transparent;
    color: #ef9123; }

.what-we-do .about_wrapper .about-contact-us .request-services,
.solutions .about_wrapper .about-contact-us .request-services {
  background: #a3cf5f;
  border: solid 3px #a3cf5f; }
  .what-we-do .about_wrapper .about-contact-us .request-services:hover,
  .solutions .about_wrapper .about-contact-us .request-services:hover {
    background: transparent;
    color: #a3cf5f; }

.about .about_wrapper .about-contact-us .request-services {
  background: #a072a5;
  border: solid 3px #a072a5; }
  .about .about_wrapper .about-contact-us .request-services:hover {
    background: transparent;
    color: #a072a5; }

.insights .about_wrapper .about-contact-us .request-services {
  background: #62b4e5;
  border: solid 3px #62b4e5; }
  .insights .about_wrapper .about-contact-us .request-services:hover {
    background: transparent;
    color: #62b4e5; }

#cms-main {
  overflow: hidden; }

.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important; }

.cms-landing .footer_section .about_wrapper {
  display: none; }

.cms-hero {
  overflow: hidden;
  height: 100vh;
  position: relative;
  z-index: 3;
  min-height: 550px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* &:before {
        content:'';
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #000;
        opacity: 0.7;
    } */ }
  .cms-hero .container {
    position: relative;
    z-index: 2;
    margin: 0; }
  .cms-hero h1 {
    color: #fff;
    font-size: 49px;
    font-weight: 700;
    line-height: 1.07;
    letter-spacing: -2px;
    margin: 0 0 27px;
    text-transform: none; }
  .cms-hero img {
    position: absolute;
    z-index: -2;
    min-width: 100%;
    min-height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover; }
  .cms-hero video {
    position: absolute;
    z-index: -2;
    min-width: 100%;
    min-height: 100%;
    left: 0;
    top: 0; }
  .cms-hero a.request-services {
    margin: 36px auto 0; }
    .cms-hero a.request-services:hover {
      color: #a3cf5f !important; }
  .cms-hero .anchor-btn {
    position: absolute;
    left: 50%;
    bottom: 35px;
    width: 40px;
    height: 40px;
    text-align: center;
    opacity: 0.7;
    margin: 0  0 0 -20px;
    border-radius: 50%;
    background: rgba(37, 39, 40, 0.7);
    transition: background 0.3s; }
    .cms-hero .anchor-btn:after {
      content: '';
      position: absolute;
      width: 12px;
      left: 50%;
      top: 50%;
      height: 12px;
      border: 3px solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      margin: -7px 0 0 -6px; }
    .cms-hero .anchor-btn:hover {
      background: #252728; }
  @media screen and (max-width: 1023px) {
    .cms-hero {
      min-height: 400px; }
      .cms-hero h1 {
        font-size: 40px;
        letter-spacing: -1px; } }
  @media screen and (max-width: 767px) {
    .cms-hero h1 {
      font-size: 32px;
      letter-spacing: -1px; }
    .cms-hero .anchor-btn {
      bottom: 20px; } }

.cms-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-110%);
  transition: transform 0.3s; }
  .cms-top.show {
    transform: translateY(0%); }
  .cms-top h2 {
    margin: 0; }
  .cms-top a.request-services {
    width: 234px;
    margin: 0 !important; }
  .cms-top .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 23px;
    padding-bottom: 23px; }
  @media screen and (max-width: 767px) {
    .cms-top .container {
      padding-top: 15px;
      padding-bottom: 15px; }
    .cms-top h2 {
      font-size: 16px;
      padding: 0 15px 0 0; }
    .cms-top a.request-services {
      padding: 8px 0; } }

.line1 {
  position: relative;
  z-index: 2; }
  .line1:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 30%;
    height: 75%;
    min-width: 400px;
    background: transparent url("../../assets/images/dashed-line1.svg") center 100% no-repeat;
    background-size: auto 100%; }

.cms-intro {
  padding: 80px 0 54px; }
  .cms-intro .anim-block {
    margin: -40px 0 -54px;
    background: transparent url("../../assets/images/grass.svg") 50% 190% repeat-x;
    overflow: hidden;
    text-align: center; }
    .cms-intro .anim-block > * {
      display: inline-block;
      vertical-align: top;
      width: 320px;
      margin: 15px -10px -145px;
      position: relative;
      transform: translate3d(0, 0, 0); }
      .cms-intro .anim-block > *:after {
        content: '';
        display: block;
        padding-bottom: 175%; }
      .cms-intro .anim-block > * svg {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%; }
    .cms-intro .anim-block #wp-anim {
      margin: 0 -10px -145px;
      width: 380px; }
    @media screen and (max-width: 767px) {
      .cms-intro .anim-block > * {
        width: 43%;
        margin: 0 -7px -18%; }
      .cms-intro .anim-block #wp-anim {
        width: 45%;
        margin: 0 -7px -18%; } }
  @media screen and (max-width: 767px) {
    .cms-intro {
      padding: 50px 0 40px; }
      .cms-intro .anim-block {
        margin: -15px 0 -40px;
        background-position: 50%  100%;
        background-size: auto 48%; } }

.cms-tabs-container {
  overflow: hidden; }
  .cms-tabs-container .container {
    position: relative;
    max-width: 1250px; }
  .cms-tabs-container .tabs {
    position: absolute;
    top: 82px;
    left: 50%;
    padding: 0;
    margin: 0;
    z-index: 2;
    transform: translateX(-50%);
    list-style: none;
    overflow: hidden;
    width: 494px;
    text-align: center; }
    .cms-tabs-container .tabs li {
      float: left;
      width: 50%; }
    .cms-tabs-container .tabs a {
      color: #fff;
      text-transform: uppercase;
      background: #707071;
      transition: background 0.3s;
      display: block;
      line-height: 56px;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      padding: 2px 0 0; }
    .cms-tabs-container .tabs a.active {
      background: #262728; }
    @media screen and (max-width: 1023px) {
      .cms-tabs-container .tabs {
        top: 50px; }
        .cms-tabs-container .tabs a {
          line-height: 50px; } }
    @media screen and (max-width: 767px) {
      .cms-tabs-container .tabs {
        top: 30px;
        width: calc(100% - 40px); }
        .cms-tabs-container .tabs a {
          line-height: 40px; } }
  .cms-tabs-container .row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .cms-tabs-container .row {
        display: block; } }
  .cms-tabs-container .right-col {
    width: 47%;
    flex: 0 0 47%;
    position: relative;
    z-index: 1; }
    .cms-tabs-container .right-col > div {
      top: -0.5%;
      right: 6%;
      left: -3%;
      position: absolute; }
      .cms-tabs-container .right-col > div svg {
        display: block;
        position: absolute;
        top: 0;
        left: 0; }
      .cms-tabs-container .right-col > div:after {
        display: block;
        content: '';
        padding-top: 121%; }
      @media screen and (max-width: 1023px) {
        .cms-tabs-container .right-col > div {
          right: -15px;
          left: -20%; } }
    .cms-tabs-container .right-col #tab-wp-anim {
      right: 1%;
      left: 2%;
      top: 0; }
      .cms-tabs-container .right-col #tab-wp-anim:after {
        padding-top: 134%; }
      @media screen and (max-width: 1023px) {
        .cms-tabs-container .right-col #tab-wp-anim {
          right: -35px;
          left: -12%; } }
    @media screen and (max-width: 767px) {
      .cms-tabs-container .right-col {
        width: 100%; }
        .cms-tabs-container .right-col > div {
          position: relative;
          top: 0;
          display: block;
          left: -15px;
          width: calc(100% + 30px); }
        .cms-tabs-container .right-col #tab-wp-anim {
          left: 0;
          margin-bottom: -14%; } }
  .cms-tabs-container a.request-services.solutions {
    background: #a3cf5f;
    border: solid 2px #a3cf5f;
    margin-top: 46px;
    max-width: 325px; }
    .cms-tabs-container a.request-services.solutions:hover {
      background: none;
      color: #a3cf5f; }
    @media screen and (max-width: 767px) {
      .cms-tabs-container a.request-services.solutions {
        margin-top: 30px; } }
  .cms-tabs-container .left-col {
    width: 40%;
    flex: 0 0 40%;
    padding: 226px 0 40px;
    min-height: 720px; }
    @media screen and (max-width: 1023px) {
      .cms-tabs-container .left-col {
        width: 43%;
        flex: 0 0 43%;
        padding: 170px 0 30px; } }
    @media screen and (max-width: 767px) {
      .cms-tabs-container .left-col {
        width: 100%;
        min-height: 0;
        padding: 25px 0 35px; } }

.cms-tool {
  padding: 95px 0 157px;
  position: relative;
  overflow: hidden;
  z-index: 2; }
  @media screen and (max-width: 1023px) {
    .cms-tool {
      padding: 80px 0 120px; } }
  @media screen and (max-width: 767px) {
    .cms-tool {
      padding: 50px 0 30px; } }
  .cms-tool .txt-block {
    margin: 0 0 62px; }
    @media screen and (max-width: 767px) {
      .cms-tool .txt-block {
        margin: 0 0 40px; } }
  .cms-tool .line2 {
    position: absolute;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 25%;
    height: 50%;
    background: transparent url("../../assets/images/dashed-line2.svg") 0 0 no-repeat;
    background-size: 100% auto; }
    @media screen and (max-width: 767px) {
      .cms-tool .line2 {
        width: 50%; } }
  .cms-tool .line3 {
    position: absolute;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    width: 30%;
    height: 80%;
    background: transparent url("../../assets/images/dashed-line3.svg") 100% 100% no-repeat;
    background-size: 100% auto; }
    @media screen and (max-width: 767px) {
      .cms-tool .line3 {
        width: 50%; } }

.cms-grid {
  max-width: 780px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  color: #252728;
  margin: 0 auto 87px; }
  @media screen and (max-width: 1023px) {
    .cms-grid {
      margin: 0 0 70px; } }
  @media screen and (max-width: 767px) {
    .cms-grid {
      margin: 0 0 50px; } }
  .cms-grid > div {
    width: 25%;
    position: relative; }
    .cms-grid > div:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -3px;
      left: 0;
      right: 0;
      background-size: 12px 2px;
      background-repeat: repeat-x;
      background-position: 0 0;
      height: 3px;
      background-image: -webkit-gradient(linear, left top, right top, color-stop(15%, #bac1c2), color-stop(16%, #d6dede));
      background-image: -webkit-linear-gradient(left, #bac1c2 15%, #d6dede 16%);
      background-image: -o-linear-gradient(left, #bac1c2 15%, #d6dede 16%);
      background-image: linear-gradient(to right, #bac1c2 15%, #d6dede 16%); }
    .cms-grid > div:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -4px;
      bottom: 0;
      right: -3px;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #bac1c2), color-stop(16%, #d6dede));
      background-image: -webkit-linear-gradient(top, #bac1c2 15%, #d6dede 16%);
      background-image: -o-linear-gradient(top, #bac1c2 15%, #d6dede 16%);
      background-image: linear-gradient(to bottom, #bac1c2 15%, #d6dede 16%);
      background-size: 2px 12px;
      background-repeat: repeat-y;
      background-position: 0 0;
      width: 3px; }
    @media screen and (max-width: 767px) {
      .cms-grid > div {
        width: 50%; } }
  .cms-grid .box {
    height: 100%;
    position: relative;
    text-align: center;
    padding-top: 100%;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #252728; }
    .cms-grid .box:hover img {
      transform: scale(1.2); }
  .cms-grid .name {
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    width: 100%;
    transform: translateY(-50%); }
    .cms-grid .name .title {
      min-height: 36px; }
    @media screen and (max-width: 767px) {
      .cms-grid .name {
        font-size: 14px; }
        .cms-grid .name .title {
          min-height: 32px; } }
  .cms-grid img {
    display: block;
    margin: 0 auto 15px;
    height: 42px;
    width: auto;
    transition: transform 0.3s ease; }

.cms-info {
  position: relative;
  max-width: 1050px;
  padding: 0 30px;
  margin: 0 auto;
  text-transform: uppercase; }
  .cms-info #tools {
    width: 111%;
    margin: 0 -5% 3px; }
    @media screen and (max-width: 767px) {
      .cms-info #tools {
        width: calc(100% + 60px);
        margin: 0 -26px; } }
  .cms-info .grid {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .cms-info .grid {
        display: none; } }
  .cms-info .holder {
    position: relative;
    z-index: 2;
    width: 25%; }
    .cms-info .holder:last-child .circle:after {
      display: none; }
    .cms-info .holder:last-child .circle img {
      width: 131%;
      margin: -27% 0 0 -10.5%; }
    @media screen and (max-width: 767px) {
      .cms-info .holder {
        margin: 0 0 50px; } }
  .cms-info .circle {
    position: relative;
    z-index: 2;
    width: 245px;
    height: 245px;
    border-radius: 50%;
    margin: 0 0 81px;
    border: 5px solid #717171; }
    .cms-info .circle img {
      width: 100%;
      height: auto;
      max-width: none; }
    .cms-info .circle:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      left: 95%;
      height: 5px;
      z-index: -1;
      background: #717171;
      margin-top: -2px; }
    @media screen and (max-width: 1023px) {
      .cms-info .circle {
        width: 190px;
        height: 190px;
        margin: 0 0 50px; }
        .cms-info .circle:after {
          width: 120%; } }
    @media screen and (max-width: 767px) {
      .cms-info .circle {
        width: 220px;
        height: 220px;
        margin: 0 auto 25px; }
        .cms-info .circle:after {
          display: none; } }
  .cms-info p {
    margin: 0;
    color: #252728;
    font-size: 25px;
    line-height: 1; }
    @media screen and (max-width: 1023px) {
      .cms-info p {
        font-size: 20px; } }
    @media screen and (max-width: 767px) {
      .cms-info p {
        font-size: 18px; } }

.cms-block {
  padding: 84px 0 0; }
  .cms-block a.request-services.solutions {
    margin-top: 41px; }
  .cms-block .container {
    position: relative;
    z-index: 3; }
  @media screen and (max-width: 1023px) {
    .cms-block {
      padding: 80px 0 0; } }
  @media screen and (max-width: 767px) {
    .cms-block {
      padding: 50px 0 0; }
      .cms-block a.request-services.solutions {
        margin-top: 30px; } }

.laptop {
  text-align: center;
  margin: calc(-3vw - 10px) 0 0;
  padding: 0 0 0 53px; }
  @media screen and (max-width: 1023px) {
    .laptop {
      padding: 0 0 0 3%; } }
  @media screen and (max-width: 767px) {
    .laptop {
      margin: 0; } }
  .laptop video {
    display: block;
    margin: 0 auto;
    background: #fff;
    max-width: 100%;
    height: auto; }
  .laptop .frame {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden; }
    .laptop .frame:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -5px;
      border: 5px solid #fff; }

.cms-slider {
  padding: 83px 0 95px;
  position: relative;
  z-index: 2;
  background: #d6dede url("../../assets/images/dashed-line5.svg") center 35% no-repeat;
  background-size: auto 160%; }
  .cms-slider > h2 {
    margin: 0 0 46px; }
  @media screen and (max-width: 1023px) {
    .cms-slider {
      padding: 80px 0;
      background-position: 30px 35%; }
      .cms-slider > h2 {
        margin: 0 0 35px; } }
  @media screen and (max-width: 767px) {
    .cms-slider {
      padding: 50px 0 40px; }
      .cms-slider > h2 {
        margin: 0 0 30px; } }
  .cms-slider .card {
    padding: 0 8px;
    text-align: left; }
    .cms-slider .card .holder {
      color: #4f585d;
      height: 100%;
      min-height: 288px;
      position: relative;
      background: #fff;
      display: block;
      text-decoration: none;
      padding: 33px 36px 60px; }
    .cms-slider .card h2 {
      color: #4f585d;
      margin: 0 0 2px; }
    .cms-slider .card p {
      font-size: 16px;
      margin: 0; }
    @media screen and (max-width: 1250px) {
      .cms-slider .card .holder {
        padding: 30px 25px 55px; } }
  .cms-slider .slick-track {
    display: flex; }
    .cms-slider .slick-track .slick-slide {
      min-height: 100%;
      height: auto; }
  .cms-slider .slick-slider {
    padding: 0 40px; }
  .cms-slider .slick-list {
    max-width: 1140px;
    margin: 0 auto; }
  .cms-slider .category {
    color: #a3cf5f;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0 0 7px;
    display: block; }
  .cms-slider .anim-btn {
    position: absolute;
    right: 17px;
    bottom: 19px;
    color: #2b3337;
    border-color: #2b3337; }
    .cms-slider .anim-btn .arrow:before {
      border-color: #2b3337; }
    .cms-slider .anim-btn .arrow:after {
      background: #2b3337; }
    @media screen and (max-width: 1250px) {
      .cms-slider .anim-btn {
        right: 20px;
        bottom: 20px; } }

.cms-cta {
  position: relative;
  z-index: 3;
  text-align: center;
  padding: 83px 0 105px;
  background: #a3cf5f;
  color: #fff; }
  .cms-cta:before {
    content: '';
    position: absolute;
    left: -126px;
    bottom: -28px;
    width: 300px;
    height: 350px;
    z-index: -1;
    background: url("../../assets/images/wp-character.png") 0 100% no-repeat;
    background-size: 100% auto; }
  .cms-cta:after {
    content: '';
    position: absolute;
    right: -63px;
    bottom: -10px;
    width: 295px;
    height: 350px;
    z-index: -1;
    background: url("../../assets/images/drupal-character.png") 100% 100% no-repeat;
    background-size: 100% auto; }
  .cms-cta a.request-services.solutions {
    margin-top: 44px; }
  @media screen and (max-width: 1023px) {
    .cms-cta {
      padding: 80px 0 100px; }
      .cms-cta:before {
        width: 200px;
        left: -84px;
        bottom: -20px; }
      .cms-cta:after {
        width: 200px;
        right: -50px;
        bottom: -8px; } }
  @media screen and (max-width: 767px) {
    .cms-cta {
      padding: 50px 0 80px; }
      .cms-cta:before {
        width: 120px;
        left: -49px;
        height: 200px;
        bottom: -13px; }
      .cms-cta:after {
        width: 120px;
        height: 200px;
        right: -27px;
        bottom: -5px; }
      .cms-cta a.request-services.solutions {
        margin-top: 30px; } }
  .cms-cta h2 {
    color: #fff;
    margin: 0 0 38px; }
    @media screen and (max-width: 767px) {
      .cms-cta h2 {
        margin: 0 0 25px; } }
  .cms-cta .request-services {
    margin: 0 auto;
    background: #fff !important;
    border-color: #fff !important;
    color: #a3cf5f; }
    .cms-cta .request-services:hover {
      background: transparent !important;
      color: #fff !important; }

.cms-cta-h {
  background: #707071;
  color: #fff;
  padding: 76px 0 78px; }
  .cms-cta-h .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cms-cta-h p {
    margin: 0; }
  .cms-cta-h a.request-services.work-overview {
    margin: 0 7px 0 0;
    width: 270px; }
    .cms-cta-h a.request-services.work-overview:hover {
      color: #a3cf5f; }
  .cms-cta-h .txt-block {
    width: 60%;
    padding: 0 0 0 10px; }
  @media screen and (max-width: 1023px) {
    .cms-cta-h {
      padding: 65px 0; }
      .cms-cta-h .txt-block {
        padding: 0; }
      .cms-cta-h a.request-services.work-overview {
        margin: 0; } }
  @media screen and (max-width: 767px) {
    .cms-cta-h {
      padding: 50px 0; }
      .cms-cta-h .container {
        display: block;
        text-align: center; }
      .cms-cta-h .txt-block {
        width: 100%; }
      .cms-cta-h a.request-services.work-overview {
        margin: 30px auto 0; } }

.fancybox-content {
  background: #d6dede;
  padding: 45px; }
  @media screen and (max-width: 1023px) {
    .fancybox-content {
      width: 90%; }
      .fancybox-content.card-popup {
        width: auto; } }
  @media screen and (max-width: 767px) {
    .fancybox-content {
      padding: 20px;
      width: 94%; } }

.popup {
  text-align: center;
  width: 800px; }
  .popup h2 {
    font: 400 24px/1.3 'Roboto Slab', serif;
    margin: 0 0 15px;
    color: #707071; }
  .popup video {
    display: block;
    width: 100%; }
  @media screen and (max-width: 1023px) {
    .popup {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .popup h2 {
      font-size: 22px; } }

.popup-holder {
  height: 0;
  position: relative;
  overflow: hidden; }

.card {
  width: 530px;
  position: relative;
  text-align: center;
  padding: 43px 0;
  min-height: 390px; }
  @media screen and (max-width: 1023px) {
    .card {
      width: 450px;
      min-height: 340px;
      padding: 35px 0; } }
  @media screen and (max-width: 767px) {
    .card {
      width: 100%;
      min-height: 0;
      padding: 20px 0 40px; } }
  .card .icon {
    display: block;
    margin: 0 0 8px; }
    .card .icon img {
      height: 56px;
      width: auto;
      display: block;
      margin: 0 auto; }
    @media screen and (max-width: 1023px) {
      .card .icon img {
        height: 45px; } }
    @media screen and (max-width: 767px) {
      .card .icon img {
        height: 40px; } }
  .card .title {
    color: #706f71;
    font: 400 25px/1.3 'Roboto Slab', serif;
    margin: 0 0 7px; }
    @media screen and (max-width: 1023px) {
      .card .title {
        font-size: 22px; } }
    @media screen and (max-width: 767px) {
      .card .title {
        font-size: 18px; } }
  .card .text {
    color: #706f71;
    font-size: 20px;
    margin: 0 auto 35px;
    max-width: 355px; }
    @media screen and (max-width: 1023px) {
      .card .text {
        font-size: 16px;
        max-width: 280px;
        margin: 0 auto 25px; } }
    @media screen and (max-width: 767px) {
      .card .text {
        font-size: 12px; } }
  .card .btn {
    transition: background 0.3s;
    min-height: 42px;
    font-size: 0;
    position: relative; }
    .card .btn:after {
      position: absolute;
      left: 0;
      right: 0;
      opacity: 1;
      font-size: 12px;
      content: 'ADD TO CMS NEEDS';
      transition: opacity 0.3s 0.3s; }
    .card .btn:before {
      transition: opacity 0.3s;
      position: absolute;
      left: 0;
      right: 0;
      font-size: 12px;
      opacity: 0;
      content: 'remove from CMS needs'; }
  .card .added:after {
    opacity: 0;
    transition-delay: 0s; }
  .card .added:before {
    transition-delay: 0.3s;
    opacity: 1; }
  .card .btn-row {
    position: absolute;
    bottom: -23px;
    left: -25px;
    right: -25px;
    overflow: hidden; }
    .card .btn-row a {
      text-transform: uppercase;
      white-space: nowrap;
      line-height: 26px;
      border: 1px solid #000;
      border-radius: 2px;
      font-weight: bold;
      font-size: 12px;
      text-decoration: none;
      color: #000;
      transition: all 0.3s ease;
      background: transparent;
      padding: 0 24px 0 0;
      position: relative;
      height: 26px; }
      .card .btn-row a .arrow {
        width: 22px;
        height: 16px;
        position: absolute;
        top: 4px;
        display: block;
        max-width: none;
        right: 5px;
        overflow: visible;
        transition: all 0.3s ease; }
        .card .btn-row a .arrow:before {
          content: '';
          position: absolute;
          transition: all 0.3s ease;
          right: 1px;
          top: 50%;
          width: 9px;
          height: 9px;
          border: 2px solid #000;
          border-width: 0 2px 2px 0;
          transform: translateY(-51%) rotate(-45deg); }
        .card .btn-row a .arrow:after {
          transition: all 0.3s ease;
          content: '';
          position: absolute;
          right: 1px;
          top: 50%;
          width: 14px;
          height: 2px;
          background: #000;
          margin-top: -1px; }
      .card .btn-row a:hover {
        background: #fff;
        border: 1px solid transparent; }
        .card .btn-row a:hover .name {
          max-width: 250px;
          padding: 0 5px 0 10px; }
    .card .btn-row .name {
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      color: #000;
      padding: 0;
      transition: all 0.3s ease;
      max-width: 0; }
    @media screen and (max-width: 767px) {
      .card .btn-row {
        left: -10px;
        right: -10px;
        bottom: -10px; }
        .card .btn-row a {
          font-size: 12px; } }
  .card .js-prev-card {
    float: left;
    padding: 0  0 0 24px !important; }
    .card .js-prev-card .arrow {
      transform: rotate(180deg);
      right: auto;
      left: 5px; }
    .card .js-prev-card:hover .name {
      padding: 0 10px 0 5px !important; }
  .card .js-next-card {
    float: right; }

* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "proxima-nova", arial, sans-serif;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }

body,
html {
  height: 100%;
  width: 100%;
  color: #707071; }

a {
  cursor: pointer;
  color: #ef9123; }

img {
  display: block;
  border: 0 none;
  max-width: 100%;
  height: auto; }

.center {
  text-align: center; }

.padding,
.container.padding {
  padding-top: 71px;
  padding-bottom: 85px; }
  @media screen and (max-width: 767px) {
    .padding,
    .container.padding {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 580px) {
    .padding,
    .container.padding {
      padding-top: 20px;
      padding-bottom: 20px; } }

#wrapper {
  background: #ffffff; }

.light-grey-bg {
  background: #d6dede; }

a.request-services {
  border-radius: 2px;
  color: #ffffff;
  text-decoration: none;
  padding: 11px 0 10px;
  text-align: center;
  margin: 10px 0 0;
  text-transform: uppercase !important;
  font-size: 12px;
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  transition: all 0.3s;
  font-family: proxima-nova !important; }
  a.request-services.case-studies {
    background: none;
    color: #2b3337;
    border: solid 2px #2b3337;
    max-width: 234px;
    margin: 0 auto; }
    a.request-services.case-studies:hover {
      background: #2b3337;
      color: #ffffff; }
  a.request-services.work {
    background: #ef9123;
    max-width: 234px;
    border: solid 2px #ef9123;
    margin-top: 30px; }
    a.request-services.work:visited {
      color: #ffffff; }
    a.request-services.work:hover {
      background: none;
      color: #ef9123; }
  a.request-services.work-overview {
    background: none;
    max-width: 234px;
    border: solid 2px #ffffff;
    margin-top: 36px; }
    a.request-services.work-overview:hover {
      background: #ffffff;
      color: #ef9123; }
  a.request-services.solutions {
    background: #a3cf5f;
    border: solid 2px #a3cf5f;
    margin-top: 36px;
    max-width: 234px; }
    a.request-services.solutions:hover {
      background: none;
      color: #a3cf5f; }
  a.request-services.solutions-overview {
    background: none;
    max-width: 270px;
    border: solid 2px #ffffff;
    margin-top: 36px; }
    a.request-services.solutions-overview:hover {
      background: #ffffff;
      color: #a3cf5f; }
  a.request-services.pink {
    background: #e74769;
    max-width: 270px;
    margin: 0 auto;
    border: solid 2px #e74769; }
    a.request-services.pink:hover {
      background: none;
      color: #e74769; }

.anim-btn {
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  line-height: 24px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  position: relative;
  padding: 0 24px 0 0;
  overflow: hidden;
  transition: all 0.3s ease;
  background: transparent; }
  .anim-btn span {
    display: inline-block;
    vertical-align: top;
    max-width: 0;
    overflow: hidden;
    color: #2b3337;
    transition: all 0.3s ease; }
  .anim-btn .arrow {
    width: 16px;
    height: 12px;
    position: absolute;
    top: 6px;
    display: block;
    max-width: none;
    right: 5px;
    overflow: visible;
    transition: all 0.3s ease; }
    .anim-btn .arrow:before {
      content: '';
      position: absolute;
      transition: all 0.3s ease;
      right: 0;
      top: 50%;
      width: 9px;
      height: 9px;
      border: 2px solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: translateY(-50%) rotate(-45deg); }
    .anim-btn .arrow:after {
      transition: all 0.3s ease;
      content: '';
      position: absolute;
      right: 1px;
      top: 50%;
      width: 14px;
      height: 2px;
      background: #ffffff;
      margin-top: -1px; }
  .ua-mobile .anim-btn {
    background: #ffffff; }
    .ua-mobile .anim-btn span {
      max-width: 200px;
      padding: 0 5px 0 10px; }
    .ua-mobile .anim-btn .arrow:before {
      border-color: #2b3337; }
    .ua-mobile .anim-btn .arrow:after {
      background: #2b3337; }
  @media screen and (max-width: 1023px) {
    .anim-btn {
      background: #ffffff; }
      .anim-btn span {
        max-width: 200px;
        padding: 0 5px 0 10px; }
      .anim-btn .arrow:before {
        border-color: #2b3337; }
      .anim-btn .arrow:after {
        background: #2b3337; } }

a:hover .anim-btn {
  background: #ffffff; }
  a:hover .anim-btn span {
    max-width: 200px;
    padding: 0 5px 0 10px; }
  a:hover .anim-btn .arrow:before {
    border-color: #2b3337; }
  a:hover .anim-btn .arrow:after {
    background: #2b3337; }

.centered {
  text-align: center; }
  .centered .request-services {
    margin: 0 auto; }

.container {
  max-width: 1030px;
  padding: 0 40px;
  margin: 0 auto;
  font-size: 18px; }
  .container .small-container {
    padding-left: 0;
    padding-right: 0; }
  .container h3 {
    margin: 0 0 18px;
    letter-spacing: -1.3px; }
  @media screen and (max-width: 1023px) {
    .container {
      font-size: 16px; }
      .container h3 {
        font-size: 24px; } }
  @media screen and (max-width: 780px) {
    .container {
      padding: 0 20px; } }
  @media screen and (max-width: 767px) {
    .container {
      font-size: 14px; }
      .container h3 {
        margin: 0 0 12px; } }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.pulse-large {
  -webkit-animation-name: pulse-large;
  -o-animation-name: pulse-large;
  animation-name: pulse-large;
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s; }

.animated {
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  13% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15); }
  25% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  37% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15); }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  13% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  25% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25);
    -ms-transform: scale3d(1.25, 1.25, 1.25);
    transform: scale3d(1.25, 1.25, 1.25); }
  37% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
